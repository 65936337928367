import React from "react";
import { useBreadcrumb } from "gatsby-plugin-breadcrumb";
import Breadcrumbs from "../components/Navigation/breadcrumbs";
import SearchResults from "../components/Search/searchResults";
import Seo from "../components/seo";

const Search = ({ location, path }) => {
  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: "Search",
  });
  return (
    <>
      <Seo title="Search" pathname={path} />
      <Breadcrumbs crumbs={crumbs} />
      <SearchResults />
    </>
  );
};

export default Search;
