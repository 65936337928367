import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useMenu } from "../../hooks/menu";
import { useLocale } from "../../hooks/locale";
import useTranslations from "../useTranslations";
import i18n from "../../../config/i18n";
import config from "../../../config/config";

const getEndpoint = (query) => {
  const { cx, path } = config.search.api;

  return (
    path +
    "?key=" +
    process.env.REACT_APP_API_KEY_SEARCH +
    "&cx=" +
    cx +
    "&q=" +
    query
  );
};

async function get(endpoint, options = {}) {
  return fetch(endpoint, options)
    .then((response) => {
      return response.ok
        ? response.json()
        : Promise.reject(response.statusText);
    })
    .then((data) => {
      return data;
    });
}

const search = (query) => {
  const endpoint = getEndpoint(query);
  return new Promise((resolve, reject) => {
    get(endpoint).then(
      (search) => {
        resolve(search);
      },
      (error) => {
        reject(error);
      }
    );
  });
};

const getBadge = (link, locale) => {
  const domain = i18n[locale].host.replace("https://", "");
  let badge = domain;
  if (link.endsWith(domain) || link.endsWith(`${domain}/`)) return badge;

  const array = link.replace(`${i18n[locale].host}/`, "").split("/");

  array.forEach((el) => {
    if (el !== "" && el !== null) badge += ` > ${el}`;
  });

  return badge;
};

const getSlug = (link, locale) => {
  const domain = i18n[locale].host.replace("https://", "");
  return link.split(domain)[1];
};
const SearchResults = () => {
  const { translate } = useStaticQuery(query);
  const { no_results, search_results, something_went_wrong } =
    useTranslations(translate);
  const { resultSearch, setSearch } = useMenu();
  const { locale } = useLocale();
  const [isLoad, setLoad] = useState(false);
  const [isError, setError] = useState(false);

  const handleSearch = async (event) => {
    setError(false);
    setLoad(true);
    search(event)
      .then((result) => {
        setSearch(result);
        setLoad(false);
      })
      .catch((err) => {
        setLoad(false);
        setError(true);
      });
  };

  const correctedQuery = (query) => {
    return (
      <>
        Did you mean:{" "}
        <span
          role="button"
          tabIndex={0}
          onKeyUp={() => {
            return null;
          }}
          className="search-spelling"
          onClick={() => {
            handleSearch(query);
          }}
        >{`${query}`}</span>{" "}
        {isLoad && <FontAwesomeIcon icon={faSpinner} spin />}
        {isError && <span>{`(${something_went_wrong})`}</span>}
      </>
    );
  };

  const getContent = () => {
    if (
      resultSearch === null ||
      parseInt(resultSearch.searchInformation.totalResults) === 0
    )
      return <p>{no_results}</p>;

    return resultSearch.items.map((item, index) => {
      return (
        <div key={index} className="row">
          <div className="col-md-8 col-12 mt-3 content">
            <div className="card-body search_card">
              <Link to={getSlug(item.link, locale)} className="active_link">
                <h3 className="search_reasult_title">{item.title}</h3>
              </Link>
              <span className="badge bg-type-result">{`${getBadge(
                item.link,
                locale
              )}`}</span>
              <p
                className="article-body search-desc mb-3"
                dangerouslySetInnerHTML={{ __html: item?.htmlSnippet }}
              ></p>
            </div>
          </div>
          <div className="col-md-4 col-12 d-flex flex-column justify-content-center "></div>
        </div>
      );
    });
  };

  return (
    <>
      <div className="container mt-5 mb-5">
        <h1 className="page-title">
          {resultSearch !== null
            ? `${search_results} "${resultSearch.queries.request[0].searchTerms}"`
            : ""}
        </h1>
        <p>
          {resultSearch !== null && resultSearch.spelling
            ? correctedQuery(resultSearch.spelling.correctedQuery)
            : ""}
        </p>
      </div>
      <div className="container container__page">{getContent()}</div>
    </>
  );
};

export default SearchResults;

const query = graphql`
  query SearchResults {
    translate: allFile(filter: { sourceInstanceName: { eq: "translations" } }) {
      edges {
        node {
          name
          translations: childTranslationsJson {
            search_results
            no_results
            something_went_wrong
          }
        }
      }
    }
  }
`;
